module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rankydank | Musician & YouTuber | Austin Texas","short_name":"Rankydank","start_url":"/","background_color":"#f002d2","theme_color":"#f002d2","display":"minimal-ui","icon":"src/images/favicon-rankydank.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0648c39348b9085dc18f2bf7f9ea70a6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
